








import {Component, Mixins} from 'vue-property-decorator';
import SearchMixin from '@/mixins/SearchMixin';
import ModelCard from '@/components/ModelCard.vue';

@Component({
  components: {ModelCard},
})
export default class SearchModels extends Mixins(SearchMixin) {}
